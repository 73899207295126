import Language from './language';

export default class Norm_Translation {
  normId: number;

  languageId: number;

  name: string;

  description?: string;

  scope?: string;

  lang?: Language;

  constructor() {
    this.name = '';
    this.normId = 0;
    this.languageId = 0;
  }
}

export class ISONorm_Translation {
  isoNormId: number;

  languageId: number;

  name: string;

  description?: string;

  scope?: string;

  lang?: Language;

  constructor() {
    this.name = '';
    this.isoNormId = 0;
    this.languageId = 0;
  }
}
