import * as React from 'react';
import CurrentUser from 'models/currentuser';
import AppError from 'utils/appError';
import Task from 'models/tasks/task';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { globalDesktopHeight, globalDesktopSize } from 'globalConstants';

export interface IAppContext {
  useDarkMode: boolean;
  setUseDarkMode: Function;
  isAppLoading: boolean;
  isContentLoading: boolean;
  showContentLoader: Function;
  hideContentLoader: Function;
  error?: AppError;
  showNotification: Function;
  hasScopes: Function;
  setError: Function;
  getAccessToken: Function;
  isAuthenticated: boolean;
  isAuthInProgress: boolean;
  user: CurrentUser;
  task: Task | undefined;
  itemId: string | undefined;
  itemStart: Date | undefined;
  isGettingItemId: boolean;
  isGettingItemStart: boolean;
  isReady: Function;
  globalDataCache: GlobalDataCache;
  firstRun: boolean;
  cacheMiss: Function;
  getGraphInterface: Function;
  isMobileView: boolean;
  windowSize: number;
  windowHeight: number;
  hasResourcePanel: boolean;
  showResourcePanel: boolean;
  isMainNavCollapsed: boolean;
  login: () => void;
  logout: () => void;
  setState: Function;
  globalFilter: string[];
  setGlobalFilter: (filter: string[]) => void
}

const appContext: IAppContext = {
  useDarkMode: false,
  setUseDarkMode: () => {},
  isAppLoading: false,
  isContentLoading: false,
  showContentLoader: () => {},
  hideContentLoader: () => {},
  error: undefined,
  showNotification: () => {},
  hasScopes: () => {},
  setError: () => {},
  getAccessToken: () => {},
  isAuthenticated: false,
  isAuthInProgress: false,
  user: CurrentUser.getEmptyUser(),
  task: undefined,
  itemId: undefined,
  itemStart: undefined,
  isGettingItemId: false,
  isGettingItemStart: false,
  isReady: () => {},
  globalDataCache: new GlobalDataCache(),
  firstRun: false,
  cacheMiss: () => {},
  getGraphInterface: () => {},
  windowSize: globalDesktopSize,
  isMobileView: false,
  windowHeight: globalDesktopHeight,
  hasResourcePanel: false,
  showResourcePanel: false,
  isMainNavCollapsed: false,
  login: () => {},
  logout: () => {},
  setState: () => {},
  globalFilter: [],
  setGlobalFilter: () => {},
};

const AppContext = React.createContext(appContext);
AppContext.displayName = 'Global app context';

export default AppContext;
