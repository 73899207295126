import { LogDTO } from './dto/logDTO';
import Norm_Translation, { ISONorm_Translation } from './norm_Translation';
import Joi from 'joi';
import { getLocalizedMessageOptions } from '../services/Localization/joiValidation';
import Tag from './tag';
import AppError from 'utils/appError';
import UserLanguage from './userLanguage';

export enum NormStatus {
  NotSet = 0,
  Activating = 1,
  Upgrading = 2,
  Ready = 9,
  Error = 99,
}

export enum ISONorms {
  ISO270012013 = 2,
  ISO270012022 = 13,
}

export default class Norm {
  normId: number;

  authSchemaId?: number;

  active: boolean;

  isoNormId?: number;

  trans?: Norm_Translation[];

  isoNorm?: ISONorm;

  status?: NormStatus;

  logo?: Blob;

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description?: string;

  scope?: string;

  constructor() {
    this.normId = 0;
    this.active = false;
    this.name = '';
    this.transIdx = -1;
  }

  getTransIdxById(languageId: number): number {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        if (this.trans[i].languageId === languageId) {
          return i;
        }
      }
    }

    return -1;
  }

  getTransIdx(langCode: string | undefined, defLangCode: string | undefined): number {
    if (this.trans && (langCode || defLangCode)) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        if (this.trans[i].lang?.code === langCode) {
          return i;
        }
      }

      //if not found, find the default organization language
      for (let i = 0; i < this.trans.length; i++) {
        if (this.trans[i].lang?.code === defLangCode) {
          return i;
        }
      }

      //if not found, find the defaultlanguage
      for (let i = 0; i < this.trans.length; i++) {
        if (this.trans[i].lang?.code === UserLanguage.getFallBack()) {
          return i;
        }
      }

      //if not found, get the first
      if (this.trans.length > 0) {
        return 0;
      }
    }

    return -1;
  }

  getLanguages(): number[] {
    const languages: number[] = [];

    if (this.trans) {
      for (let i = 0; i < this.trans.length; i++) {
        const t: Norm_Translation = this.trans[i];
        languages.push(t.languageId);
      }
    }

    return languages;
  }

  isEqual(item: Norm): boolean {
    if (item.name !== this.name) return false;
    if (item.description !== this.description) return false;
    if (item.normId !== this.normId) return false;
    if (item.active !== this.active) return false;
    if (item.isoNormId !== this.isoNormId) return false;
    if (item.authSchemaId !== this.authSchemaId) return false;

    return true;
  }

  clone(): Norm {
    const newNorm = new Norm();

    newNorm.name = this.name;
    newNorm.description = this.description;
    newNorm.scope = this.scope;
    newNorm.normId = this.normId;
    newNorm.active = this.active;
    newNorm.isoNormId = this.isoNormId;
    newNorm.status = this.status;
    newNorm.logo = this.logo?.slice();
    newNorm.transIdx = this.transIdx;
    newNorm.trans = this.trans ? [...this.trans] : undefined;
    newNorm.authSchemaId = this.authSchemaId;

    return newNorm;
  }

  // Validate function that validates the contents of the fields that have user input and can be written to the database
  // - Set abortEarly=false to make sure all errors are returned for the class
  // - Use getLocalizedMessageOptions() from the Localization service to get localized error messages
  // - The localizedFields array must be used to give each field in the error message a localized label
  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    const schema: Joi.ObjectSchema = Joi.object({
      name: Joi.string().max(512).required().label(localizedFields['name']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ name: this.name }, { abortEarly: false });
  }
}

export class ISONorm {
  isoNormId: number;

  published: boolean;

  upgradeISONormId?: number;

  installUsingPackage: boolean;

  blockUpgrade?: boolean;

  allowDowngrade?: boolean;

  webURL?: string;

  trans?: ISONorm_Translation[];

  norm?: Norm;

  transIdx: number;

  //translation properties are flattened on the main class for the current language of the user
  name: string;

  description?: string;

  constructor() {
    this.isoNormId = 0;
    this.transIdx = 0;
    this.name = '';
    this.published = false;
    this.installUsingPackage = false;
  }

  getTransIdxById(languageId: number): number {
    if (this.trans) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        if (this.trans[i].languageId === languageId) {
          return i;
        }
      }
    }

    return -1;
  }

  getTransIdx(langCode: string | undefined, defLangCode: string | undefined): number {
    if (this.trans && (langCode || defLangCode)) {
      //find requested language
      for (let i = 0; i < this.trans.length; i++) {
        if (this.trans[i].lang?.code === langCode) {
          return i;
        }
      }

      //if not found, find the default organization language
      for (let i = 0; i < this.trans.length; i++) {
        if (this.trans[i].lang?.code === defLangCode) {
          return i;
        }
      }

      //if not found, find the defaultlanguage
      for (let i = 0; i < this.trans.length; i++) {
        if (this.trans[i].lang?.code === UserLanguage.getFallBack()) {
          return i;
        }
      }

      //if not found, get the first
      if (this.trans.length > 0) {
        return 0;
      }
    }

    return -1;
  }

  getLanguages(): number[] {
    const languages: number[] = [];

    if (this.trans) {
      for (let i = 0; i < this.trans.length; i++) {
        const t: ISONorm_Translation = this.trans[i];
        languages.push(t.languageId);
      }
    }

    return languages;
  }

  clone(): ISONorm {
    const newNorm = new ISONorm();

    newNorm.name = this.name;
    newNorm.description = this.description;
    newNorm.upgradeISONormId = this.upgradeISONormId;
    newNorm.installUsingPackage = this.installUsingPackage;
    newNorm.published = this.published;
    newNorm.isoNormId = this.isoNormId;
    newNorm.blockUpgrade = this.blockUpgrade;
    newNorm.allowDowngrade = this.allowDowngrade;
    newNorm.webURL = this.webURL;
    newNorm.norm = this.norm?.clone();
    newNorm.transIdx = this.transIdx;
    newNorm.trans = this.trans ? [...this.trans] : undefined;

    return newNorm;
  }
}

export class ISONormUpgrade {
  source: ISONorm;

  target: ISONorm;

  updateSharePoint: boolean | undefined;

  hasInvalidLinks: boolean | undefined;

  relatedStandards: ISONorm[];

  constructor() {
    this.source = new ISONorm();
    this.target = new ISONorm();
    this.relatedStandards = [];
  }
}

export class ISONormUpgradeResult {
  sourceStandardId: number;

  targetStandardId: number;

  log: LogDTO;

  movedRelatedStandards: number[];

  upgradeLog: ISONormUpgradeLog[];

  tag: Tag | undefined;

  taskCount: number;

  error: AppError | undefined;

  constructor() {
    this.sourceStandardId = 0;
    this.targetStandardId = 0;
    this.log = new LogDTO('');
    this.movedRelatedStandards = [];
    this.upgradeLog = [];
    this.taskCount = 0;
  }
}

export class ISONormUpgradeLog {
  upgradeISONormId: number;

  sourceControlId: number;

  targetControlId: number;

  sourceISOControlId: number;

  targetISOControlId: number;

  spUpgraded?: boolean;

  constructor() {
    this.upgradeISONormId = 0;
    this.sourceControlId = 0;
    this.targetControlId = 0;
    this.sourceISOControlId = 0;
    this.targetISOControlId = 0;
  }
}
