import { Approval } from './approval';
import { apiRequest, graphSharepointPagesRequest , graphSharepointLibraryRequest, graphSharepointListRequest } from './../services/Auth/authConfig';
import { HomeAccount } from './userSetting';
import Entity from 'models/entity';
import Joi from 'joi';
import { getLocalizedMessageOptions } from 'services/Localization/joiValidation';
import ResourceList, { ResourceListType } from './resourceList';
import {
  graphGetListItem,
  graphGetDriveItemPreview,
  graphGetDocumentLibraryItemThumbnails,
  graphGetSite,
  graphGetSitePage,
  graphValidateListItem,
  graphValidateDriveItem,
  graphValidateSitePageItem,
  graphGetDriveItem,
  graphGetDriveItemVersions,
  graphGetSitePageListItem,
  graphGetListItemVersions,
  graphGetSitePageList,
  graphPublishSitePage,
  graphPublishDriveItem,
} from 'services/Graph/graphService';
import { IAppContext } from 'App/AppContext';

import { DriveItem, DriveItemVersion, ListItem, ListItemVersion } from 'microsoft-graph';
import { ISite, ISitePage } from 'services/Graph/SharepointInterfaces';
import logger from 'services/Logging/logService';
import ResourceLinkChannel from './resourceLinkChannel';
import Group from './group';
import { TeamsChannel } from './teamsChannel';
import { newGuidNil } from 'utils/guid';
import AppError from 'utils/appError';
import { TFunction } from 'i18next';
import { IGraphInterface } from 'App/AppContextProvider';
import Activity from './activity';
import { areDifferent } from 'utils/array';
import { apiUpdateList } from 'services/Api/listService';
import { apiUpdateLink } from 'services/Api/linkService';
import { IOwner } from './owner';

let newLinkId = -1;

export enum ResourceLinkPublishingState {
  None = '',
  Draft = 'draft',
  Checkout = 'checkout',
  Published = 'published',
}

export default class ResourceLink implements IOwner {
  linkId: number;

  listId: number;

  ownerId?: string;

  ownerRoleId?: string;

  listItemId?: string;

  driveItemId?: string;

  pageId?: string;

  linkName: string;

  linkURL: string; //don't use to navigate: use getWebURL()

  linkURLFragment: string;

  pinned: boolean;

  usedCount: number;

  entity?: Entity;

  valid?: boolean;

  isValidated: boolean;

  list: ResourceList;

  resourceLinkChannels?: ResourceLinkChannel[];

  normIds?: number[];

  kbGroups?: KBTeam[];

  approvals?: Approval[];

  changed?: Date;

  version?: string;

  versions: ResourceLinkVersion[];

  publishingState?: ResourceLinkPublishingState;

  activity?: Activity;

  constructor() {
    this.linkId = newLinkId;
    this.linkName = '';
    this.linkURL = '';
    this.linkURLFragment = '';
    this.usedCount = 0;
    this.list = new ResourceList();
    this.listId = 0;
    this.pinned = false;
    this.isValidated = false;
    this.version = '';
    this.changed = undefined;
    this.versions = [];
    newLinkId = newLinkId - 1;
  }

  isEqual(item: ResourceLink): boolean {
    //Do not compare data from SharePoint
    //- changed, versions, publishing state, validation
    //Do not compare approvals
    if (item.linkId !== this.linkId) return false;
    if (item.linkName !== this.linkName) return false;
    if (item.linkURL !== this.linkURL) return false;
    if (item.listId !== this.listId) return false;
    if (item.listItemId !== this.listItemId) return false;
    if (item.driveItemId !== this.driveItemId) return false;
    if (item.pageId !== this.pageId) return false;
    if (item.pinned !== this.pinned) return false;
    if (item.ownerId !== this.ownerId) return false;
    if (item.ownerRoleId !== this.ownerRoleId) return false;

    if (
      areDifferent(item.normIds, this.normIds, (a: number, b: number) => {
        return a === b;
      }) === true
    ) {
      return false;
    }

    return true;
  }

  clone(): ResourceLink {
    const newItem = new ResourceLink();
    newItem.linkId = this.linkId;
    newItem.linkName = this.linkName;
    newItem.linkURL = this.linkURL;
    newItem.usedCount = this.usedCount;
    newItem.listId = this.listId;
    newItem.listItemId = this.listItemId;
    newItem.driveItemId = this.driveItemId;
    newItem.list = this.list;
    newItem.pageId = this.pageId;
    newItem.pinned = this.pinned;
    newItem.valid = this.valid;
    newItem.isValidated = this.isValidated;
    newItem.approvals = this.approvals ? [...this.approvals] : undefined;
    newItem.changed = this.changed ? new Date(this.changed) : undefined;
    newItem.version = this.version;
    newItem.publishingState = this.publishingState;
    newItem.ownerId = this.ownerId;
    newItem.versions = [...this.versions];
    newItem.normIds = this.normIds ? [...this.normIds] : undefined;
    newItem.ownerRoleId = this.ownerRoleId;

    return newItem;
  }

  isWholeList(): boolean {
    if (!this.driveItemId && !this.listItemId && !this.pageId && this.linkURL === 'list') return true;

    return false;
  }

  setWholeList(list: ResourceList): boolean {
    this.list = list;
    this.listId = list.listId;
    this.linkName = list.name;
    this.linkURL = 'list';
    this.driveItemId = undefined;
    this.listItemId = undefined;
    this.pageId = undefined;

    return false;
  }

  validate(localizedFields: Record<string, string>): Joi.ValidationResult {
    if (this.isWholeList()) {
      //return a dummy validation result
      const schema: Joi.ObjectSchema = Joi.object({
        name: Joi.string().min(4).max(4),
      });

      return schema.validate({ url: this.linkURL }, { abortEarly: false });
    }

    switch (this.list.listType) {
      case ResourceListType.DocumentLibrary:
        if (!this.driveItemId) throw new AppError('DriveItemId must have a value');
        break;
      case ResourceListType.CustomList:
        if (!this.listItemId) throw new AppError('ListItemId must have a value');
        break;
      case ResourceListType.SitePageLibrary:
        if (!this.pageId) throw new AppError('PageId must have a value');
        break;
    }

    const schema: Joi.ObjectSchema = Joi.object({
      name: Joi.string().min(1).max(512).required().label(localizedFields['name']),
      url: Joi.string().uri().min(1).max(800).required().label(localizedFields['url']),
    }).prefs(getLocalizedMessageOptions());

    return schema.validate({ url: this.linkURL.trim(), name: this.linkName.trim() }, { abortEarly: false });
  }

  async getThumbnail(appContext: IAppContext): Promise<string> {
    if (!this.list.spDriveId || !this.driveItemId) return '';

    try {
      const graphInterface = await appContext.getGraphInterface(
        graphSharepointLibraryRequest.scopes,
        this.list.altTenantId,
      );
      const thumbUrl = await graphGetDocumentLibraryItemThumbnails(
        graphInterface.client,
        this.list.spDriveId,
        this.driveItemId,
      );

      return thumbUrl ?? '';
    } catch (err) {
      logger.debug('Error while getting the thumbnail url', err);

      return '';
    }
  }

  async publish(appContext: IAppContext): Promise<void> {
    try {
      switch (this.list.listType) {
        case ResourceListType.SitePageLibrary:
          if (!this.list.spSiteId || !this.pageId) {
            logger.debug('spSiteId or pageId is empty', this);

            return;
          }
          const graphInterfacePage = await appContext.getGraphInterface(
            graphSharepointLibraryRequest.scopes,
            this.list.altTenantId,
          );
          await graphPublishSitePage(graphInterfacePage.client, this.list.spSiteId, this.pageId);
          break;
        case ResourceListType.DocumentLibrary:
          if (!this.list.spDriveId || !this.driveItemId) {
            logger.debug('spDriveId or driveItemId is empty', this);

            return;
          }
          const graphInterfaceDoc = await appContext.getGraphInterface(
            graphSharepointLibraryRequest.scopes,
            this.list.altTenantId,
          );
          await graphPublishDriveItem(graphInterfaceDoc.client, this.list.spDriveId, this.driveItemId);
          break;
        default:
          logger.debug('Invalid type for publication', this);
      }
    } catch (err) {
      logger.debug('publish', err);

      throw err;
    }
  }

  getMetaDataBatchRequest(): string | undefined {
    if (this.isWholeList()) {
      return undefined;
    }

    let request: string | undefined = undefined;
    if (this.list && this.list.enableMetaData === true && !this.list.isVirtual) {
      switch (this.list.listType) {
        case ResourceListType.DocumentLibrary:
          if (this.driveItemId && this.list.spDriveId) {
            request = `/drives/${this.list.spDriveId}/items/${this.driveItemId}/versions`;
          }
          break;
        case ResourceListType.SitePageLibrary:
          if (this.list.spSiteId && this.list.spListId && this.listItemId) {
            request = `sites/${this.list.spSiteId}/lists/${this.list.spListId}/items/${this.listItemId}/versions`;
          }
          break;
      }
    }

    return request;
  }

  applyMetaDataBatchResultListItem(versions: ListItemVersion[]) {
    if (!versions) return;
    this.versions = [];

    for (let idx = 0; idx < versions.length; idx++) {
      const version = versions[idx];
      if (version.id) {
        const newVersion = new ResourceLinkVersion();
        newVersion.id = version.id;
        newVersion.version = version.id;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fields: any = version.fields;
        if (fields && fields['Modified']) {
          newVersion.date = new Date(fields['Modified']);
        }
        this.versions.push(newVersion);
      }
    }

    if (this.versions.length > 0) {
      const latestVersion = this.versions[0];
      this.changed = latestVersion.date;
      this.version = latestVersion.id;
    }
  }

  applyMetaDataBatchResultDriveItem(versions: DriveItemVersion[]) {
    if (!versions) return;
    this.versions = [];

    for (let idx = 0; idx < versions.length; idx++) {
      const version = versions[idx];
      if (version.id) {
        const newVersion = new ResourceLinkVersion();
        newVersion.id = version.id;
        newVersion.date = version?.lastModifiedDateTime ? new Date(version?.lastModifiedDateTime) : new Date();
        newVersion.version = version.id;
        this.versions.push(newVersion);
      }
    }

    if (this.versions.length > 0) {
      const latestVersion = this.versions[0];
      this.changed = latestVersion.date;
      this.version = latestVersion.id;
      this.publishingState = this.version.endsWith('0')
        ? ResourceLinkPublishingState.Published
        : ResourceLinkPublishingState.Draft;
    }
  }

  setMetaDataForDriveItem(item: DriveItem) {
    this.version = item.publication?.versionId ?? '';
    this.publishingState = this.version.endsWith('0')
      ? ResourceLinkPublishingState.Published
      : ResourceLinkPublishingState.Draft;
    this.changed = item?.lastModifiedDateTime ? new Date(item.lastModifiedDateTime) : undefined;
  }

  async setMetaData(
    graphInterfacePages: IGraphInterface | undefined,
    graphInterfaceDocuments: IGraphInterface | undefined,
    appContext: IAppContext,
  ): Promise<void> {
    //
    // Get all versions and last modification date of the item
    //
    try {
      if (this.isWholeList()) {
        return;
      }

      if (this.list && this.list.enableMetaData === true && !this.list.isVirtual) {
        switch (this.list.listType) {
          case ResourceListType.DocumentLibrary:
            if (!graphInterfaceDocuments) return;
            if (!this.list.spDriveId || !this.driveItemId) {
              logger.debug('Error while getting the meta data: drive id or drive item id is empty', this.list);

              return;
            }

            const versions = await graphGetDriveItemVersions(
              graphInterfaceDocuments.client,
              this.list.spDriveId,
              this.driveItemId,
            );

            this.applyMetaDataBatchResultDriveItem(versions);
            break;
          case ResourceListType.SitePageLibrary:
            if (!graphInterfacePages) return;
            if (!this.list.spSiteId || !this.pageId) {
              logger.debug('Error while getting the meta data: site id or page id is empty', this.list);

              return;
            }

            //try to fix spListId
            if (!this.list.spListId) {
              const pageList = await graphGetSitePageList(graphInterfacePages.client, this.list.spSiteId);
              this.list.spListId = pageList?.id;
              if (this.list.spListId) {
                const accessToken = await appContext.getAccessToken(apiRequest.scopes);
                await apiUpdateList(accessToken, this.list);
                logger.debug('spListId updated', this.list);
              }
            }

            //try to fix listItemId
            if (!this.listItemId && this.list.spListId) {
              const listItem = await graphGetSitePageListItem(
                graphInterfacePages.client,
                this.list.spSiteId,
                this.list.spListId,
                this.linkURL,
              );
              if (listItem?.id) {
                this.listItemId = listItem.id;
                const accessToken = await appContext.getAccessToken(apiRequest.scopes);
                await apiUpdateLink(this, false, accessToken, appContext.globalDataCache);
                logger.debug('listItemId updated', this);
              }
            }

            if (this.listItemId && this.list.spListId) {
              //get the complete version history
              const versions = await graphGetListItemVersions(
                graphInterfacePages.client,
                this.list.spSiteId,
                this.list.spListId,
                this.listItemId,
              );

              this.applyMetaDataBatchResultListItem(versions);
            }

            //there should be at least 1 version
            //when this fails, try to get the latest version from the page
            if (!this.versions || this.versions.length === 0) {
              //we cannot get the list item. fill the version history, only from the page model
              const page = await graphGetSitePage(graphInterfacePages.client, this.list.spSiteId, this.pageId, false);
              this.publishingState = this.getPublishingStateFromSitePage(page?.publishingState.level);
              if (page?.publishingState?.versionId) {
                const newVersion = new ResourceLinkVersion();
                newVersion.id = page?.publishingState?.versionId;
                newVersion.date = page?.lastModifiedDateTime ? new Date(page?.lastModifiedDateTime) : new Date();
                newVersion.version = page?.publishingState?.versionId;
                this.changed = newVersion.date;
                this.version = newVersion.id;
              }
            }
            break;
          default:
            this.version = '?';
            this.changed = undefined;
        }
      }
    } catch (err) {
      logger.debug('Error while getting the meta data', err);
    }
  }

  async getEmbedURL(appContext: IAppContext): Promise<string> {
    logger.debug('getEmbedURL', this);

    if (this.isWholeList()) {
      //return the web URL of the list
      if (this.list) {
        return this.list.webURL || '';
      }
    }

    let url: string = this.linkURL;

    try {
      if (this.list) {
        switch (this.list.listType) {
          case ResourceListType.DocumentLibrary:
            if (this.driveItemId && this.list.spDriveId) {
              const graphInterface = await appContext.getGraphInterface(
                graphSharepointLibraryRequest.scopes,
                this.list.altTenantId,
              );

              const embedUrl = await graphGetDriveItemPreview(
                graphInterface.client,
                this.list.spDriveId,
                this.driveItemId,
              );

              if (embedUrl) {
                url = embedUrl;
              }
            }
            break;
          default:
            url = await this.getWebURL(appContext);

            return url;
        }
      }
    } catch (err) {
      logger.debug('Error while getting the embed url', err);
    } finally {
      if (this.linkURLFragment && this.linkURLFragment.trim() !== '') {
        url += this.linkURLFragment;
      }
    }

    return url;
  }

  async getWebURL(appContext: IAppContext): Promise<string> {
    logger.debug('getWebURL', this);

    if (this.isWholeList() && this.list) {
      //return the web URL of the list
      return this.list.webURL || '';
    }

    let url: string = this.linkURL;

    try {
      if (this.list) {
        switch (this.list.listType) {
          case ResourceListType.DocumentLibrary:
            if (this.driveItemId && this.list.spDriveId) {
              const graphInterface = await appContext.getGraphInterface(
                graphSharepointLibraryRequest.scopes,
                this.list.altTenantId,
              );

              const item = await graphGetDriveItem(graphInterface.client, this.list.spDriveId, this.driveItemId);
              url = item && item.webUrl ? item.webUrl : this.linkURL;
            }
            break;

          case ResourceListType.CustomList:
            if (this.listItemId && this.list.spSiteId && this.list.spListId) {
              const graphInterface = await appContext.getGraphInterface(
                graphSharepointListRequest.scopes,
                this.list.altTenantId,
              );
              const item = await graphGetListItem(
                graphInterface.client,
                this.list.spSiteId,
                this.list.spListId,
                this.listItemId,
              );
              const listItemUrl = this.buildLinkURLFromListItem(item);
              if (listItemUrl) {
                url = listItemUrl;
              }
            }
            break;

          case ResourceListType.SitePageLibrary:
            if (this.pageId && this.list.spSiteId) {
              const graphInterface = await appContext.getGraphInterface(
                graphSharepointPagesRequest.scopes,
                this.list.altTenantId,
              );

              const page: ISitePage = await graphGetSitePage(
                graphInterface.client,
                this.list.spSiteId,
                this.pageId,
                false,
              );

              url = page.webUrl;
              if (!url.startsWith('https://')) {
                const site: ISite = await graphGetSite(graphInterface.client, this.list.spSiteId);
                url = site.webUrl + '/' + url;
              }
            }
            break;
          case ResourceListType.WebURL:
          //nothing to-do
        }
      }
    } catch (err) {
      logger.debug('Error while getting the web url', err);
    } finally {
      if (this.linkURLFragment && this.linkURLFragment.trim() !== '') {
        url += this.linkURLFragment;
      }
    }

    return url;
  }

  buildLinkURLFromListItem = (item: ListItem | undefined): string | undefined => {
    if (item && item.webUrl) {
      let url = item.webUrl.substring(0, item.webUrl.lastIndexOf('/'));
      url = url + '/DispForm.aspx?';
      url = url + 'ID=';
      url = url + item.id?.toString();

      return url;
    }

    return undefined;
  };

  async isValid(appContext: IAppContext): Promise<boolean | undefined> {
    this.isValidated = true;
    if (this.isWholeList()) return true;
    if (!this.list) return false;
    if (this.list.isVirtual) return true;

    if (this.list.altTenantId) {
      //don't validate links in other tenants except the home account of the current user
      const currentHomeAccount = appContext.globalDataCache.userSettings.get(HomeAccount) as string;
      if (!currentHomeAccount || this.list.altTenantId !== currentHomeAccount) {
        return undefined;
      }
    }

    switch (this.list.listType) {
      case ResourceListType.DocumentLibrary:
        if (this.driveItemId && this.list.spDriveId) {
          const graphInterface = await appContext.getGraphInterface(
            graphSharepointLibraryRequest.scopes,
            this.list.altTenantId,
          );

          return graphValidateDriveItem(graphInterface.client, this.list.spDriveId, this.driveItemId);
        } else {
          return false;
        }

      case ResourceListType.CustomList:
        if (this.listItemId && this.list.spSiteId && this.list.spListId) {
          const graphInterface = await appContext.getGraphInterface(
            graphSharepointListRequest.scopes,
            this.list.altTenantId,
          );

          return graphValidateListItem(graphInterface.client, this.list.spSiteId, this.list.spListId, this.listItemId);
        } else {
          return false;
        }

      case ResourceListType.SitePageLibrary:
        if (this.pageId && this.list.spSiteId) {
          const graphInterface = await appContext.getGraphInterface(
            graphSharepointListRequest.scopes,
            this.list.altTenantId,
          );

          return graphValidateSitePageItem(graphInterface.client, this.list.spSiteId, this.pageId);
        } else {
          return false;
        }

      case ResourceListType.WebURL:
        return fetch(this.linkURL, { mode: 'no-cors' })
          .then((r) => {
            return true;
          })
          .catch((e) => {
            return false;
          });

      default:
        return undefined;
    }
  }

  getPublishingStateFromSitePage = (state: string | undefined | null): ResourceLinkPublishingState => {
    switch (state) {
      case 'published':
        return ResourceLinkPublishingState.Published;
      case 'draft':
        return ResourceLinkPublishingState.Draft;
      case 'checkout':
        return ResourceLinkPublishingState.Checkout;
      default:
        return ResourceLinkPublishingState.None;
    }
  };

  static getPagePublicationStateText(publishingState: ResourceLinkPublishingState | undefined, t: TFunction): string {
    //the states are hard coded by Microsoft Graph API
    switch (publishingState) {
      case ResourceLinkPublishingState.Published:
        return t('sharepoint:PagePublicationState.Published');
      case ResourceLinkPublishingState.Draft:
        return t('sharepoint:PagePublicationState.Draft');
      case ResourceLinkPublishingState.Checkout:
        return t('sharepoint:PagePublicationState.Checkout');
      default:
        return '';
    }
  }
}

export class ResourceLinkVersion {
  id: string;

  date: Date;

  version: string;

  constructor() {
    this.id = '';
    this.date = new Date();
    this.version = '';
  }
}

export interface ILinkRow {
  item: ResourceLink;
  isBusy: boolean;
}

export class EntityLink extends Entity {
  pinned: boolean;

  linkURLFragment?: string;

  constructor() {
    super();
    this.pinned = false;
  }
}

export class KBTeam {
  group: Group;

  channels: TeamsChannel[];

  links: ResourceLink[];

  hash: string;

  constructor() {
    this.group = new Group(newGuidNil());
    this.channels = [];
    this.links = [];
    this.hash = newGuidNil();
  }

  setHash() {
    this.hash = this.group.id + '#' + this.channels.map((c) => c.channelId ?? newGuidNil()).join(',');
  }
}
