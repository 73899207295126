import Task from "./tasks/task";

export enum EntityTypes {
  NotSet = 0,
  Requirement = 1,
  Control = 2,
  Task = 3,
  Risk = 4,
  Objective = 5,
  Process = 6,
  List = 7,
  Link = 8,
  Package = 9,
  Standard = 10,
  ControlControl = 11,
  RiskControl = 12,
  ControlOrTheme = 13,
  Tag = 14,
  ControlTag = 15,
  ControlLink = 16,
  RiskTag = 17,
  RiskLink = 18,
  TaskTag = 19,
  TaskLink = 20,
  RiskTask = 21,
  ControlTask = 22,
  Dashboard = 23,
  Widget = 24,
  Drive = 25,
  DriveFolder = 26,
  ProcessControl = 27,
  ProcessTag = 28,
  ProcessLink = 29,
  ProcessTask = 30,
  ObjectiveControl = 31,
  ObjectiveTag = 32,
  ObjectiveLink = 33,
  ObjectiveTask = 34,
  ProcessObjective = 35,
  RiskProcess = 36,
  RiskObjective = 37,
  AuditTrail = 38,
  CommentTrail = 39,
  Asset = 40,
  Classification = 41,
  StringValue = 42,
  KPI = 43,
  ProcessUser = 44,
  ObjectiveUser = 45,
  TaskUser = 46,
  ThemeUser = 47,
  ControlUser = 48,
  AssetUser = 49,
  RiskUser = 50,
  ThemeTag = 51,
  AssetTag = 52,
  TaskStatus = 53,
  KPILink = 54,
  KPIData = 55,
  KPIAlert = 56,
  TaskType = 57,
  TaskTypeField = 58,
  Webhook = 59,
  WebhookRun = 60,
  Activity = 61,
  AssetClassification = 62,
  AssetContainer = 63,
  AssetLink = 64,
  AssetRisk = 65,
  AssetTask = 66,
  AssetProcess = 67,
  AssetNorm = 68,
  KPIControl = 69,
  KPITag = 70,
  ObjectiveKPI = 71,
  KPIDataTransform = 72,
  TaskTask = 73,
  RiskClassification = 74,
  Group = 75,
  Role = 76,
  AuthSchema = 77,
  AuthSchemaLine = 78,
  SharePointItem = 79,
}

export class EntityType {
  id: EntityTypes;

  name: string;

  constructor(id: EntityTypes, name: string) {
    this.id = id;
    this.name = name;
  }
}

export default class Entity {
  entityId: number;

  typeOfEntity: EntityTypes;

  entityCode?: string;

  entityOrgUnitId?: string;

  data?: string;

  // All supported entities: Task
  // Should a story require it, add one of the following:
  //  Control | Process | Risk | Theme | Process | Objective | Asset |
  originalObject?: Task;

  // The following properties are client-side only
  isDeleted: boolean;

  private _entityName: string;

  constructor(
    entityId: number = 0,
    typeOfEntity: EntityTypes = EntityTypes.NotSet,
    entityName: string = '',
    entityCode?: string,
    data?: string,
    origObj?: Task
  ) {
    this.entityId = entityId;
    this.typeOfEntity = typeOfEntity;
    this._entityName = entityName;
    this.entityCode = entityCode;
    this.isDeleted = false;
    this.data = data;
    this.originalObject = origObj
  }

  get entityName(): string {
    if (this.entityCode) {
      return `${this.entityCode} ${this._entityName}`;
    } else {
      return this._entityName;
    }
  }

  set entityName(name: string) {
    this._entityName = name;
  }

  get id(): string {
    return `${this.typeOfEntity}-${this.entityId}`;
  }

  clone() {
    const newEntity = new Entity();

    newEntity.entityId = this.entityId;
    newEntity._entityName = this._entityName;
    newEntity.entityCode = this.entityCode;
    newEntity.typeOfEntity = this.typeOfEntity;
    newEntity.data = this.data;
    newEntity.isDeleted = this.isDeleted;

    return newEntity;
  }
}
