import React, { ReactNode } from 'react';
import CurrentUser from 'models/currentuser';
import AppError from 'utils/appError';
import { globalDesktopSize, globalDesktopHeight } from 'globalConstants';
import { toast } from 'react-toastify';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { getLocalStorageData, LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import { DefLanguageCode } from 'models/setting';
import AppContext, { IAppContext } from './AppContext';
import { Client } from '@microsoft/microsoft-graph-client';
import Logger from 'services/Logging/logService';
import { IAppProps } from './App';
import outlookService from 'services/Office/outlookService';
import { apiRequest } from 'services/Auth/authConfig';

//
// Types
//
export type AuthStateUpdate = (
  isAuthenticated?: boolean | undefined,
  user?: CurrentUser | undefined,
  isAuthInProgress?: boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any | undefined,
) => void;

export type SetGlobalDataCache = (globalDataCache: GlobalDataCache) => void;

export type IGraphInterface = {
  accessToken: string;
  client: Client;
};

//
// Global vars
//
export let globalOrgUnitId: string | undefined = undefined;
export let globalUserLang: string | undefined = undefined;
export let globalDefOrgLang: string | undefined = undefined;

interface IAppContextProps extends IAppProps {
  children?: ReactNode;
}

class AppContextProvider extends React.Component<IAppContextProps, IAppContext> {
  constructor(props: IAppProps) {
    super(props);

    this.state = {
      useDarkMode: false,
      setUseDarkMode: this.setUseDarkMode,
      isAppLoading: false,
      isContentLoading: false,
      showContentLoader: this.showContentLoader,
      hideContentLoader: this.hideContentLoader,
      error: undefined,
      showNotification: this.showNotification,
      hasScopes: this.hasScopes,
      setError: this.setErrorMessage,
      getAccessToken: this.getAccessToken,
      isAuthenticated: false,
      isAuthInProgress: true, // set to true because we start with a silent SSO request
      user: CurrentUser.getEmptyUser(),
      task: undefined,
      itemId: undefined,
      itemStart: undefined,
      isGettingItemId: false,
      isGettingItemStart: false,
      isReady: this.isReady,
      globalDataCache: new GlobalDataCache(),
      firstRun: false,
      cacheMiss: this.cacheMiss,
      getGraphInterface: this.getGraphInterface,
      windowSize: globalDesktopSize,
      isMobileView: false, // we set this true to load Normaltaskdetial in pane
      windowHeight: globalDesktopHeight,
      hasResourcePanel: false,
      showResourcePanel: false,
      isMainNavCollapsed: false,
      login: this.login,
      logout: this.logout,
      setState: this.setState,
      globalFilter: [],
      setGlobalFilter: () => {},
    };
  }

  //
  // Startup
  //
  componentDidMount() {
    this.Initialize();
  }

  isOutlookDarkMode() {
    //switch to dark mode when the background color of Outlook is 'dark', meaning that all 3 RGB components are below average
    try {
      Logger.debug('office theme', Office.context.officeTheme);
      if (Office.context.officeTheme) {
        const rgb = Office.context.officeTheme.bodyBackgroundColor;
        Logger.debug('office background color', rgb);
        if (!rgb || rgb.length < 6) return false;

        const R: string = rgb.substring(0, 2);
        const G: string = rgb.substring(2, 4);
        const B: string = rgb.substring(4, 6);

        if (R < '80' && G < '80' && B < '80') {
          Logger.debug('switching to dark mode');

          return true;
        } else {
          return false;
        }
      } else {
        return getLocalStorageData(this.state, LocalStorageKeys.DarkModeOutlook) === 'true';
      }
    } catch (err) {
      return false;
    }
  }

  Initialize = async () => {
    try {
      // Set the light or dark mode
      var useDarkMode = this.isOutlookDarkMode();
      this.setState({
        useDarkMode: useDarkMode,
      });

      await outlookService.ssoSilent(this.authStateUpdate, this.setGlobalDataCache);
    } catch (err) {
      Logger.debug('Error while initializing', err);
      this.setErrorMessage(err);
    }
  };

  //
  // Loader functions
  //
  showContentLoader = () => this.setState({ isContentLoading: true });

  hideContentLoader = () => this.setState({ isContentLoading: false });

  isReady = (): boolean => {
    const ready: boolean =
      !this.state.isAuthInProgress &&
      !this.state.isGettingItemId &&
      !this.state.isAppLoading &&
      !this.state.isGettingItemStart;

    Logger.debug('ready', ready);

    return ready;
  };

  //
  // Light and dark mode functions
  //
  setUseDarkMode = (useDarkMode: boolean) => {
    setLocalStorageData(this.state, LocalStorageKeys.DarkModeOutlook, useDarkMode.toString());
    this.setState({ useDarkMode: useDarkMode });
  };

  //
  // Authentication service wrapper functions
  //
  authStateUpdate = (
    isAuthenticated?: boolean | undefined,
    user?: CurrentUser | undefined,
    isAuthInProgress?: boolean | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any | undefined,
  ) => {
    // Update the state with 1 statement, otherwise timing issues will arrise
    // So for each state property to set, check if it's defined and if so, set it, otherwise set it to the current state
    const newState = {
      isAuthenticated: isAuthenticated !== undefined ? isAuthenticated : this.state.isAuthenticated,
      isAuthInProgress: isAuthInProgress !== undefined ? isAuthInProgress : this.state.isAuthInProgress,
      user: user !== undefined ? user : this.state.user,
      error: error !== undefined ? error : this.state.error,
    };

    if (user && this.state.user.id !== user.id) {
      // When user is changed, let the global data cache know so it can change the language
      this.state.globalDataCache.setCurrentUserLanguage(user);

      // set language headers
      this.setLanguages(user);
    }

    this.setState(newState);
  };

  logout = () => {
    outlookService.logout(this.authStateUpdate);
  };

  login = () => {
    outlookService.login(this.authStateUpdate, this.setGlobalDataCache);
  };

  getAccessToken = async (scopes: string[]): Promise<string> => {
    let token: string = '';
    if (this.isApiScope(scopes)) {
      token = await outlookService.getAccessToken();
    } else {
      token = await outlookService.getGraphToken();
    }

    return token;
  };

  getGraphInterface = async (scopes: string[], tenantId?: string): Promise<IGraphInterface> => {
    //Get a graph client interface
    //Scopes are fixed for the Outlook add-in in the manifest
    //When a tenantId is supplied, Outlook cannot obtain a graph client for another tenant
    const accessToken = await outlookService.getGraphToken();
    const client = outlookService.getGraphClient(accessToken);
    const graph: IGraphInterface = { accessToken, client };

    return graph;
  };

  isApiScope = (scopes: string[]): boolean => {
    return scopes[0] === apiRequest.scopes[0];
  };

  //
  // Global error message functions
  //
  showNotification = (msg: string, isError: boolean = false) => {
    if (isError) {
      toast.error(msg);
    } else {
      toast(msg);
    }
  };

  hasScopes = async (scopes: string[]) => {
    // return await AuthService.hasScopes(this.publicClientApplication, scopes);
    return null;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setErrorMessage = (error: string | any) => {
    this.setState({
      error: this.normalizeError(error),
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  normalizeError = (error: string | any): AppError | undefined => {
    if (!error) {
      return undefined;
    }
    var normalizedError: AppError;
    if (typeof error === 'string') {
      var errParts = error.split('|');
      normalizedError = errParts.length > 1 ? new AppError(errParts[1], '', errParts[0]) : new AppError(error);
    } else {
      normalizedError = new AppError(
        error.message,
        error.code ? error.code : '',
        JSON.stringify(error),
        error.stack ? error.stack : '',
      );
    }

    return normalizedError;
  };

  //
  // Global data cache function
  //
  cacheMiss = async (count: number) => {
    //disable this because it can cause infinte render loops
  };

  setGlobalDataCache = (globalDataCache: GlobalDataCache) => {
    Logger.debug('setGlobalDataCache');
    globalDataCache.setAppContext(this.state);
    this.setState({ globalDataCache: globalDataCache.clone() });
  };

  //
  // Helpers
  //
  setLanguages = (user: CurrentUser) => {
    globalUserLang = user.language.code;
    globalDefOrgLang = this.state.globalDataCache.settings.get(DefLanguageCode) as string;
  };

  //
  // Mobile functions
  //
  getWindowWidth = (): number => {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  };

  getWindowHeight = (): number => {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  };

  onResize = () => {
    const width = this.getWindowWidth();
    const height = this.getWindowHeight();
    this.setState({
      windowSize: width,
      windowHeight: height,
      isMobileView: width >= globalDesktopSize ? false : true,
    });
  };

  //
  // Main render
  //
  render() {
    return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
  }
}

export default AppContextProvider;
