import {
  IRenderFunction,
  IDetailsHeaderProps,
  IDetailsColumnRenderTooltipProps,
  TooltipHost,
  Sticky,
  StickyPositionType,
  Label,
  FontIcon,
  Stack,
  Text,
  ITextStyles,
  IFontStyles,
  IDropdownOption,
  IPanelProps,
  IPanelHeaderRenderer,
} from '@fluentui/react';
import {
  assetIcon,
  controlIcon,
  documentIcon,
  globalStackTokensGapSmall,
  globalTextStylesBold,
  infoIcon,
  kpiIcon,
  libraryIcon,
  listTypeDocLibraryIcon,
  objectiveIcon,
  processIcon,
  riskIcon,
  sharepointIcon,
  standardsIcon,
  taskIcon,
  themeIcon,
} from './globalStyles';
import Control from 'models/control';
import Theme from 'models/theme';
import Risk from 'models/risk';
import { IAppContext } from 'App/AppContext';
import { TFunction } from 'i18next';
import Objective from 'models/objective/objective';
import { EntityStatus } from 'models/entityStatus';
import Process from 'models/process/process';
import Task from 'models/tasks/task';
import Entity, { EntityTypes } from 'models/entity';
import { appRoles } from 'services/Auth/appRoles';
import Asset from 'models/asset/asset';
import KPI from 'models/kpi/kpi';
import Tag from 'models/tag';
import ResourceLink from 'models/resourceLink';
import CopyId from 'components/Utils/CopyId';
import StripeSubscription from 'models/Stripe/StripeSubscription';
import { apiGetNorms } from 'services/Api/normService';
import { apiRequest } from 'services/Auth/authConfig';
import { SubscriptionTypes } from 'utils/subscription';
import { Role } from 'models/auth/role';
import Norm from 'models/norm';
import TaskType from 'models/tasks/taskType';
import ResourceList from 'models/resourceList';
import { IAuthObject } from 'models/auth/authSchema';
import { sortOnNumber, sortOnString } from 'utils/sorting';
import { PDCAStatus } from 'utils/pdca';

//
// Global render helpers
//
export const onRenderDetailsHeaderGlobal: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
  if (!props) {
    return null;
  }

  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      {defaultRender!(props)}
    </Sticky>
  );
};

export const onRenderDetailsHeaderNoPaddingTopGlobal: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
  if (!props) {
    return null;
  }
  const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
    <TooltipHost {...tooltipHostProps} />
  );

  props.styles = { root: { paddingTop: 0 } };

  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );
};

export const onRenderPanelHeaderGlobal = (
  title: string,
  id: string | number,
  hasCloseButton: boolean,
): IRenderFunction<IPanelProps> => {
  return (
    props?: IPanelProps,
    defaultRender?: IPanelHeaderRenderer,
    headerTextId?: string | undefined,
  ): JSX.Element | null => {
    return (
      <Stack
        grow={hasCloseButton ? 1 : undefined}
        horizontal
        tokens={globalStackTokensGapSmall}
        verticalAlign="end"
        styles={{ root: { paddingLeft: 24 } }}
      >
        <Text variant="xLarge" styles={globalTextStylesBold}>
          {title}
        </Text>
        <CopyId id={id} />
      </Stack>
    );
  };
};

export const onRenderTextWithInfo = (
  text: string,
  info: string,
  variant?: keyof IFontStyles,
  styles?: Partial<ITextStyles>,
) => {
  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
      <Text variant={variant} styles={styles}>
        {text}
      </Text>
      <TooltipHost content={info}>
        <FontIcon {...infoIcon}></FontIcon>
      </TooltipHost>
    </Stack>
  );
};

export const onRenderLabelWithInfo = (label: string, info: string) => {
  return (
    <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
      <Label>{label}</Label>
      <TooltipHost content={info}>
        <FontIcon {...infoIcon}></FontIcon>
      </TooltipHost>
    </Stack>
  );
};

export const onRenderGridPermissions = (
  item: IAuthObject | undefined,
  appContext: IAppContext,
  t: TFunction<string[]>,
): JSX.Element | undefined => {
  if (!item) return undefined;
  if (!item.authSchemaId) {
    return <Text>{t('adminAuth:AuthSchemaPicker.Placeholder')}</Text>;
  } else {
    const schema = appContext.globalDataCache.authSchemas.get(item.authSchemaId);

    return <Text>{schema.name || t('adminAuth:AuthSchemaPicker.Placeholder')}</Text>;
  }
};

//
// Treelist hierarchy helpers
//
const getId = (item: Control | Theme | Risk | Process | Objective) => {
  if (item instanceof Control) return item.controlId;
  else if (item instanceof Theme) return item.themeId;
  else if (item instanceof Risk) return item.riskId;
  else if (item instanceof Process) return item.processId;
  else if (item instanceof Objective) return item.objectiveId;

  return -1;
};

const getParentId = (item: Control | Theme | Process | Objective) => {
  if (item instanceof Control) return item.parentControlId;
  else if (item instanceof Theme) return item.parentThemeId;
  else if (item instanceof Process) return item.parentProcessId;
  else if (item instanceof Objective) return item.parentObjectiveId;

  return -1;
};

export function isValidToRemoveFromList(
  itemToRemove: Control | Theme | Process | Objective,
  itemsToRemove: (Control | Theme | Process | Objective)[],
  allItems: (Control | Theme | Process | Objective)[],
): boolean {
  if (getParentId(itemToRemove) === undefined) return true;
  if (
    itemsToRemove.find((item: Control | Theme | Process | Objective) => getId(item) === getParentId(itemToRemove)) !==
    undefined
  )
    return false;

  const parentControl = allItems.find((item) => getId(item) === getParentId(itemToRemove));
  if (!parentControl) return true;

  return isValidToRemoveFromList(parentControl, itemsToRemove, allItems);
}

//
// Freshdesk widget API
//

export const freshdeskWidgetOpen = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('open');
};

export const freshdeskWidgetClose = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('close');
};

export const freshdeskWidgetOpenTicket = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('open', 'ticketForm');
};

export const freshdeskWidgetSetSubject = (subject: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('identify', 'ticketForm', {
    subject: subject,
  });
};

export const freshdeskWidgetSetContactInfo = (name: string, email: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('identify', 'ticketForm', {
    name: name,
    email: email,
  });
};

export const freshdeskWidgetSetTranslations = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as { [key: string]: any })['FreshworksWidget']('setLabels', {
    nl: {
      contact_form: {
        subject: 'Onderwerp',
      },
    },
  });
};

//
// Status helpers
//
export const getEntityStatusTextForItem = (
  item: Control | Process | Theme | undefined,
  t: TFunction<string[]>,
): string => {
  if (item) {
    switch (item.state) {
      case EntityStatus.Todo:
        return t('translation:EntityStatus.Todo');
      case EntityStatus.Designing:
        return t('translation:EntityStatus.Designing');
      case EntityStatus.Implementing:
        return t('translation:EntityStatus.Implementing');
      case EntityStatus.Implemented:
        return t('translation:EntityStatus.Implemented');
    }
  }

  return '';
};

// global functions for rendering string from models
export const getEntityStatusText = (state: EntityStatus, t: TFunction<string[]>): string => {
  switch (state) {
    case EntityStatus.Todo:
      return t('translation:EntityStatus.Todo');
    case EntityStatus.Designing:
      return t('translation:EntityStatus.Designing');
    case EntityStatus.Implementing:
      return t('translation:EntityStatus.Implementing');
    case EntityStatus.Implemented:
      return t('translation:EntityStatus.Implemented');
  }
};

export const getPDCAStatusText = (state: PDCAStatus, t: TFunction<string[]>): string => {
  switch (state) {
    case PDCAStatus.Plan:
      return t('translation:PDCAStatus.Plan');
    case PDCAStatus.Do:
      return t('translation:PDCAStatus.Do');
    case PDCAStatus.Check:
      return t('translation:PDCAStatus.Check');
    case PDCAStatus.Act:
      return t('translation:PDCAStatus.Act');
  }
};

export const getEntityStatusTextList = (t: TFunction<string[]>): IDropdownOption<EntityStatus>[] => {
  return [
    {
      key: EntityStatus.Todo,
      text: t('translation:EntityStatus.Todo'),
    },
    {
      key: EntityStatus.Designing,
      text: t('translation:EntityStatus.Designing'),
    },
    {
      key: EntityStatus.Implementing,
      text: t('translation:EntityStatus.Implementing'),
    },
    {
      key: EntityStatus.Implemented,
      text: t('translation:EntityStatus.Implemented'),
    },
  ];
};

export const applyEntityStatusFilter = (filter: string[], item: Theme | Control | Process): boolean => {
  if (filter.includes(EntityStatus.Todo.toString()) && item.state === EntityStatus.Todo) return true;
  if (filter.includes(EntityStatus.Designing.toString()) && item.state === EntityStatus.Designing) return true;
  if (filter.includes(EntityStatus.Implementing.toString()) && item.state === EntityStatus.Implementing) return true;
  if (filter.includes(EntityStatus.Implemented.toString()) && item.state === EntityStatus.Implemented) return true;

  return false;
};

//
// Entity helpers
//
export const getEntityName = (entity: Entity, t: TFunction<string[]>) => {
  return getEntityTypeName(entity.typeOfEntity, t);
};

export const getEntityTypeName = (type: EntityTypes, t: TFunction<string[]>) => {
  let typeName: string = '';
  switch (type) {
    case EntityTypes.Control:
      typeName = t('translation:General.EntityType.Control');
      break;
    case EntityTypes.Task:
      typeName = t('translation:General.EntityType.Task');
      break;
    case EntityTypes.Risk:
      typeName = t('translation:General.EntityType.Risk');
      break;
    case EntityTypes.Requirement:
      typeName = t('translation:General.EntityType.Requirement');
      break;
    case EntityTypes.Dashboard:
      typeName = t('translation:General.EntityType.Dashboard');
      break;
    case EntityTypes.KPILink:
      typeName = t('translation:General.EntityType.KPILink');
      break;
    case EntityTypes.Link:
      typeName = t('translation:General.EntityType.Link');
      break;
    case EntityTypes.List:
      typeName = t('translation:General.EntityType.List');
      break;
    case EntityTypes.Objective:
      typeName = t('translation:General.EntityType.Objective');
      break;
    case EntityTypes.Process:
      typeName = t('translation:General.EntityType.Process');
      break;
    case EntityTypes.Package:
      typeName = t('translation:General.EntityType.Package');
      break;
    case EntityTypes.Standard:
      typeName = t('translation:General.EntityType.Standard');
      break;
    case EntityTypes.Tag:
      typeName = t('translation:General.EntityType.Tag');
      break;
    case EntityTypes.Widget:
      typeName = t('translation:General.EntityType.Widget');
      break;
    case EntityTypes.Asset:
      typeName = t('translation:General.EntityType.Asset');
      break;
    case EntityTypes.Classification:
      typeName = t('translation:General.EntityType.Classification');
      break;
    case EntityTypes.KPI:
      typeName = t('translation:General.EntityType.KPI');
      break;
    case EntityTypes.TaskType:
      typeName = t('translation:General.EntityType.TaskType');
      break;
    case EntityTypes.SharePointItem:
      typeName = t('translation:General.EntityType.SharePointItem');
      break;
    default:
      break;
  }

  return typeName;
};

export const getEntity = (
  input:
    | Control
    | Theme
    | Risk
    | Process
    | Objective
    | Task
    | Asset
    | KPI
    | Tag
    | ResourceLink
    | Norm
    | TaskType
    | ResourceList,
  data?: string,
): Entity => {
  if (input instanceof Control) {
    return new Entity(input.controlId, EntityTypes.Control, input.name, input.code, data);
  } else if (input instanceof Theme) {
    return new Entity(input.themeId, EntityTypes.Requirement, input.name, input.code, data);
  } else if (input instanceof Risk) {
    return new Entity(input.riskId, EntityTypes.Risk, input.name, input.code, data);
  } else if (input instanceof Process) {
    return new Entity(input.processId, EntityTypes.Process, input.name, input.code, data);
  } else if (input instanceof Objective) {
    return new Entity(input.objectiveId, EntityTypes.Objective, input.name, input.code, data);
  } else if (input instanceof Task) {
    return new Entity(input.taskId, EntityTypes.Task, input.name, undefined, data, input);
  } else if (input instanceof Asset) {
    return new Entity(input.assetId, EntityTypes.Asset, input.name, input.code, data);
  } else if (input instanceof KPI) {
    return new Entity(input.kpiId, EntityTypes.KPI, input.name, undefined, data);
  } else if (input instanceof Tag) {
    return new Entity(input.tagId, EntityTypes.Tag, input.value(), undefined, data);
  } else if (input instanceof ResourceLink) {
    return new Entity(input.linkId, EntityTypes.Link, input.linkName, undefined, data);
  } else if (input instanceof Norm) {
    return new Entity(input.normId, EntityTypes.Standard, input.name, undefined, data);
  } else if (input instanceof TaskType) {
    return new Entity(input.taskTypeId, EntityTypes.TaskType, input.name, undefined, data);
  } else if (input instanceof ResourceList) {
    return new Entity(input.listId, EntityTypes.List, input.name, data, undefined);
  }

  return new Entity();
};

export const getEntityIcon = (item: Entity): JSX.Element | null => {
  if (item.typeOfEntity === EntityTypes.Control) return <FontIcon {...controlIcon} />;
  if (item.typeOfEntity === EntityTypes.Requirement) return <FontIcon {...themeIcon} />;
  if (item.typeOfEntity === EntityTypes.Risk) return <FontIcon {...riskIcon} />;
  if (item.typeOfEntity === EntityTypes.Process) return <FontIcon {...processIcon} />;
  if (item.typeOfEntity === EntityTypes.Objective) return <FontIcon {...objectiveIcon} />;
  if (item.typeOfEntity === EntityTypes.Task) return <FontIcon {...taskIcon} />;
  if (item.typeOfEntity === EntityTypes.Asset) return <FontIcon {...assetIcon} />;
  if (item.typeOfEntity === EntityTypes.KPI) return <FontIcon {...kpiIcon} />;
  if (item.typeOfEntity === EntityTypes.Standard) return <FontIcon {...standardsIcon} />;
  if (item.typeOfEntity === EntityTypes.Link) return <FontIcon {...listTypeDocLibraryIcon} />;
  if (item.typeOfEntity === EntityTypes.List) return <FontIcon {...libraryIcon} />;
  if (item.typeOfEntity === EntityTypes.TaskType) return <FontIcon {...documentIcon} />;

  return null;
};

export const getEntityIconName = (type: EntityTypes): string | undefined => {
  if (type === EntityTypes.NotSet) return 'AllApps';
  if (type === EntityTypes.Control) return controlIcon.iconName;
  if (type === EntityTypes.Requirement) return themeIcon.iconName;
  if (type === EntityTypes.Risk) return riskIcon.iconName;
  if (type === EntityTypes.Process) return processIcon.iconName;
  if (type === EntityTypes.Objective) return objectiveIcon.iconName;
  if (type === EntityTypes.Task) return taskIcon.iconName;
  if (type === EntityTypes.Asset) return assetIcon.iconName;
  if (type === EntityTypes.KPI) return kpiIcon.iconName;
  if (type === EntityTypes.Standard) return standardsIcon.iconName;
  if (type === EntityTypes.Link) return listTypeDocLibraryIcon.iconName;
  if (type === EntityTypes.List) return libraryIcon.iconName;
  if (type === EntityTypes.TaskType) return documentIcon.iconName;
  if (type === EntityTypes.SharePointItem) return sharepointIcon.iconName;

  return undefined;
};

//
// Role helpers
//
export const GetRoleName = (role: Role, t: TFunction<string[]>): string => {
  if (role.systemRoleId) return GetRoleTranslation(role.systemRoleId, t);

  return role.name;
};

export const GetRoleTranslation = (role: number, t: TFunction<string[]>): string => {
  switch (role) {
    case appRoles.User:
      return t(`translation:Roles.User`);
    case appRoles.Manager:
      return t(`translation:Roles.Manager`);
    case appRoles.Admin:
      return t(`translation:Roles.Admin`);
    case appRoles.Consultant:
      return t(`translation:Roles.Consultant`);
    case appRoles.LicenseManager:
      return t(`translation:Roles.LicenseManager`);
    case appRoles.OrgAdmin:
      return t(`translation:Roles.OrgAdmin`);
    case appRoles.TenantISOAdmin:
      return t(`translation:Roles.TenantISOAdmin`);
    case appRoles.TenantGlobalAdmin:
      return t(`translation:Roles.TenantGlobalAdmin`);
  }

  return t(`translation:Roles.Empty`);
};

export const getSystemRolesDisplayString = (roles: number[], t: TFunction<string[]>): string => {
  let rolestring = '';

  if (roles) {
    roles.sort((a, b) => sortOnNumber(a, b));

    for (const role of roles) {
      if (rolestring.length > 0) {
        rolestring += ', ';
      }
      rolestring += GetRoleTranslation(role, t);
    }
  }

  if (rolestring.length === 0) {
    rolestring = GetRoleTranslation(appRoles.User, t);
  }

  return rolestring;
};

export const getCustomRolesDisplayString = (roles: Role[]): string => {
  return roles
    .map((r) => r.name)
    .sort((a, b) => sortOnString(a, b))
    .join(', ');
};

//
// Subscription helpers
//
export const getSubscriptionTypeText = (type: SubscriptionTypes, t: TFunction<string[]>): string => {
  switch (type) {
    case SubscriptionTypes.Basic:
      return t('adminSubscription:Basic.Title');
    case SubscriptionTypes.Business:
      return t('adminSubscription:Business.Title');
    case SubscriptionTypes.Premium:
      return t('adminSubscription:Premium.Title');
    case SubscriptionTypes.Partner:
      return t('adminSubscription:Partner.Title');
    case SubscriptionTypes.Internal:
      return t('adminSubscription:Internal.Title');
    case SubscriptionTypes.Custom:
      return t('adminSubscription:Custom.Title');
    case SubscriptionTypes.Container:
      return t('adminSubscription:Container.Title');
  }
};

export const getSubscriptionStandardCount = async (appContext: IAppContext): Promise<number> => {
  try {
    const accessToken = await appContext.getAccessToken(apiRequest.scopes);
    const allStandards = await apiGetNorms(true, false, accessToken);

    return allStandards.length;
  } catch (err) {
    throw err;
  }
};

export const getSubscriptionMaxStandards = (appContext: IAppContext): number => {
  return StripeSubscription.getMaxStandards(appContext.user.login.subscriptionType);
};

export const hasReachedMaxStandards = (appContext: IAppContext, count: number): boolean => {
  const max = getSubscriptionMaxStandards(appContext);
  if (max >= 0) {
    return count >= max;
  } else {
    return false;
  }
};
