import { mapFromSettings } from 'mappings/dataMapping';
import LicenseDTO from 'models/dto/licenseDTO';
import TenantDTO, { TenantOnboardingDTO, TenantOnboardingStepsDTO } from 'models/dto/tenantDTO';
import Tenant, { TenantOnboarding, TenantOnboardingSteps } from 'models/tenant';

export function mapFromTenant(tenantDTO: TenantDTO): Tenant {
  const tenant = new Tenant(tenantDTO.id, tenantDTO.name);
  tenant.parentId = tenantDTO.parentId;
  tenant.topLevelParentTenantId = tenantDTO.topLevelParentTenantId;
  tenant.azureTenantId = tenantDTO.azureTenantId;
  tenant.created = tenantDTO.created;
  tenant.storeMgmtState = tenantDTO.storeMgmtState;
  tenant.onboardingState = new TenantOnboarding(); //we don't get this at the tenant level, a seperate API is used: apiGetTenantOnboardingState
  tenant.subscriptionType = tenantDTO.subscriptionType;
  tenant.settings = tenantDTO.settings ? mapFromSettings(tenantDTO.settings) : undefined;
  tenant.timeZoneCode = tenantDTO.timeZoneCode;
  tenant.currentUserLicensed = tenantDTO.currentUserLicensed;

  return tenant;
}

export function mapToTenant(tenant: Tenant): TenantDTO {
  const tenantDto = new TenantDTO();
  tenantDto.id = tenant.id;
  tenantDto.parentId = tenant.parentId;
  tenantDto.topLevelParentTenantId = tenant.topLevelParentTenantId;
  tenantDto.name = tenant.name;
  tenantDto.subscriptionType = tenant.subscriptionType;
  tenantDto.azureTenantId = tenant.azureTenantId;
  tenantDto.timeZoneCode = tenant.timeZoneCode;

  return tenantDto;
}

export function mapFromTenants(tenantDTOs: TenantDTO[] | undefined): Tenant[] {
  if (!tenantDTOs) return [];

  return tenantDTOs.map((t) => mapFromTenant(t));
}

//
// Onboarding
//
export function mapFromOnboardingState(
  onboardingStateDto: TenantOnboardingDTO | undefined,
): TenantOnboarding | undefined {
  if (!onboardingStateDto) return undefined;

  const onboardingState = new TenantOnboarding();
  onboardingState.leadSource = onboardingStateDto?.leadSource;
  onboardingState.packageIds = onboardingStateDto.packageIds ? [...onboardingStateDto.packageIds] : [];
  onboardingState.onboardingSteps = mapFromOnboardingSteps(onboardingStateDto.onboardingSteps);

  return onboardingState;
}

export function mapFromOnboardingSteps(onboardingStepsDto: TenantOnboardingStepsDTO): TenantOnboardingSteps {
  const onboardingSteps = new TenantOnboardingSteps();
  if (onboardingStepsDto) {
    onboardingSteps.step1 = onboardingStepsDto.step1;
    onboardingSteps.step2 = onboardingStepsDto.step2;
    onboardingSteps.step3 = onboardingStepsDto.step3;
  }

  return onboardingSteps;
}

export function mapFromTenantLicenses(tenant: Tenant, licenseDTO: LicenseDTO): Tenant {
  const newTenant = new Tenant(tenant.id, tenant.name);
  newTenant.numberOfUsers = licenseDTO.numberOfUsers; //total number of registered users in the system
  newTenant.totalLicenses = licenseDTO.totalLicenses; //userLicenses + mgmtLicenses
  newTenant.usedUserLicenses = licenseDTO.usedUserLicenses; //total licensed assigned to a user
  newTenant.usedMgMtLicenses = licenseDTO.usedMgMtLicenses; //total licensed assigned to a manager
  newTenant.userLicenses = licenseDTO.userLicenses; //number of user licenses in the subscription
  newTenant.mgMtLicenses = licenseDTO.mgMtLicenses; //number of management licenses in the subscription

  return newTenant;
}

export function mapToOnboardingState(onboardingState: TenantOnboarding | undefined): TenantOnboardingDTO | undefined {
  if (!onboardingState) return undefined;

  const onboardingStateDto = new TenantOnboardingDTO();
  onboardingStateDto.leadSource = onboardingState?.leadSource;
  onboardingStateDto.packageIds = [...onboardingState.packageIds];
  onboardingStateDto.timeZoneOffset = onboardingState.timeZoneOffset;
  onboardingStateDto.onboardingSteps = mapToOnboardingSteps(onboardingState.onboardingSteps);

  return onboardingStateDto;
}

export function mapToOnboardingSteps(onboardingSteps: TenantOnboardingSteps): TenantOnboardingStepsDTO {
  const onboardingStepsDto = new TenantOnboardingStepsDTO();
  onboardingStepsDto.step1 = onboardingSteps.step1;
  onboardingStepsDto.step2 = onboardingSteps.step2;
  onboardingStepsDto.step3 = onboardingSteps.step3;

  return onboardingStepsDto;
}
