import { TFunction } from 'i18next';
import Task from 'models/tasks/task';

export enum TaskRelationship {
  None = 0,
  FollowUp = 1,
}

export enum TaskRelationshipDirection {
  None = 0,
  To = 1,
  From = 2,
}

export class TaskTask {
  taskIdFrom: number;

  taskIdTo: number;

  relationship: TaskRelationship;

  taskFrom?: Task;

  taskTo?: Task;

  constructor(fromId: number, toId: number, relation: TaskRelationship) {
    this.taskIdFrom = fromId;
    this.taskIdTo = toId;
    this.relationship = relation;
  }

  clone = (): TaskTask => {
    const clone = new TaskTask(this.taskIdFrom, this.taskIdTo, this.relationship);
    clone.taskFrom = this.taskFrom;
    clone.taskTo = this.taskTo;

    return clone;
  }

  task = (): Task | undefined => {
    return this.taskFrom ?? this.taskTo;
  };

  direction = (currentTaskId: number): TaskRelationshipDirection => {
    if (this.taskIdFrom === currentTaskId) return TaskRelationshipDirection.To;
    if (this.taskIdTo === currentTaskId) return TaskRelationshipDirection.From;

    return TaskRelationshipDirection.None;
  };

  relationText = (currentTaskId: number, t: TFunction<string[]>): string => {
    const dir = this.direction(currentTaskId);
    switch (dir) {
      case TaskRelationshipDirection.From:
        switch (this.relationship) {
          case TaskRelationship.FollowUp:
            return t('task:Context.RelationTexts.FromFollowUp');
        }
        break;
      case TaskRelationshipDirection.To:
        switch (this.relationship) {
          case TaskRelationship.FollowUp:
            return t('task:Context.RelationTexts.ToFollowUp');
        }
        break;
    }

    return '';
  };
}
