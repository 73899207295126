export class RiskScorePriority {
  name: string;

  start: number;

  end: number;

  color: string;

  timeFrame: string;

  cost: string;

  constructor() {
    this.name = '';
    this.start = 0;
    this.end = 0;
    this.color = '#aaaaaa';
    this.timeFrame = '';
    this.cost = '';
  }
}

export default class RiskScoreModel {
  matrix: number;

  explainationLinkId: number;

  priorities: RiskScorePriority[];

  constructor() {
    this.matrix = 0;
    this.explainationLinkId = -1;
    this.priorities = [];
  }

  toJSON(): string {
    const riskScoreModel = {
      matrix: this.matrix,
      explainationLinkId: this.explainationLinkId,
      priorities: this.priorities.map((r: RiskScorePriority) => {
        return {
          name: r.name,
          start: r.start,
          end: r.end,
          color: r.color,
          timeFrame: r.timeFrame,
          cost: r.cost,
        };
      }),
    };

    return JSON.stringify(riskScoreModel);
  }

  fromJSON(patternString: string | undefined) {
    try {
      if (patternString) {
        const patternObject = JSON.parse(patternString);

        this.matrix = patternObject.matrix;
        this.explainationLinkId = patternObject.explainationLinkId;

        for (const item of patternObject.priorities) {
          const priority = item as unknown as RiskScorePriority;
          const riskScorePriority = new RiskScorePriority();

          riskScorePriority.name = priority.name;
          riskScorePriority.start = priority.start;
          riskScorePriority.end = priority.end;
          riskScorePriority.color = priority.color;
          riskScorePriority.timeFrame = priority.timeFrame;
          riskScorePriority.cost = priority.cost;

          this.priorities.push(riskScorePriority);
        }
      }
    } catch {
      //invalid input. Ignore and rely on the default values set in the constructor
    }
  }

  getColor(score: number): string {
    const foundPriority = this.priorities.find(
      (_priority: RiskScorePriority) => _priority.start <= score && _priority.end >= score,
    );

    if (foundPriority) return foundPriority.color;

    return 'white';
  }

  getPriority(score: number): RiskScorePriority | undefined {
    const foundPriority = this.priorities.find(
      (_priority: RiskScorePriority) => _priority.start <= score && _priority.end >= score,
    );

    return foundPriority;
  }

  getPriorityIndex(priority: RiskScorePriority): number {
    return this.priorities.findIndex((p) => p.start === priority.start);
  }

  addNewPriority(): RiskScorePriority {
    const newPrio = new RiskScorePriority();
    newPrio.end = this.matrix * this.matrix;

    if (this.priorities.length === 0) {
      newPrio.start = 1;
    } else {
      const prevStart: number = this.priorities[this.priorities.length - 1].start;
      const prevEnd: number = this.priorities[this.priorities.length - 1].end;
      const rangePrevZone: number = prevEnd - prevStart + 1;
      const halfRangePrevZone: number = Math.ceil(rangePrevZone / 2);
      newPrio.start = newPrio.end - halfRangePrevZone + 1;
    }

    return newPrio;
  }

  resizeMatrix(size: number) {
    if (size < 2 || size > 7) {
      return;
    }

    const oldSize = this.matrix * this.matrix;
    const newSize = size * size;

    //resize
    if (size > this.matrix) {
      this.priorities.forEach((priority) => {
        priority.start = Math.floor((priority.start / oldSize) * newSize);
        priority.end = Math.floor((priority.end / oldSize) * newSize);
      });
    } else if (size < this.matrix) {
      this.priorities.forEach((priority) => {
        priority.start = Math.ceil((priority.start / oldSize) * newSize);
        priority.end = Math.ceil((priority.end / oldSize) * newSize);
      });
    }

    //make priorities adjecent again
    this.priorities.forEach((priority, index) => {
      if (index > 0) {
        const prevPrio = this.priorities[index - 1];
        priority.start = prevPrio.end + 1;
      }
    });

    //due to rounding, priorities shift to higher values. remove any invalid priorities
    this.priorities = this.priorities.filter((priority) => priority.start <= priority.end);

    //set start and end
    this.priorities[0].start = 1;
    this.priorities[this.priorities.length - 1].end = newSize;

    this.matrix = size;
  }

  clone(): RiskScoreModel {
    const newRiskModel = new RiskScoreModel();
    newRiskModel.matrix = this.matrix;
    newRiskModel.explainationLinkId = this.explainationLinkId;
    newRiskModel.priorities = [...this.priorities];

    return newRiskModel;
  }
}
